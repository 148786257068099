
export const BACKEND_DOMAIN = "https://chinese-jobs-cd9dcefbf9e3.herokuapp.com"
//https://chinese-jobs-cd9dcefbf9e3.herokuapp.com
// Values for salary scrollers
export const minOptions = [16000, 17000, 18000, 19000, 20000, 21000, 22000, 23000, 24000, 25000, 26000, 27000, 28000, 29000, 30000];
export const maxOptions = [20000, 21000, 22000, 23000, 24000, 25000, 26000, 27000, 28000, 29000, 30000, 31000, 32000, 33000, 34000, 35000, 36000];

export const jobTypes = ['Full-time', 'Part-time', 'Internship', 'Short contract / seasonal'];


export const specialisms = ['University', 'High School', 'Middle School', 'Primary School', 'Kindergarten', 'Training School', 'Other'];
export const postedBy = ['Company', 'Recruiter', 'Direct'];

export const instructions = {
  position: 'Write the job title here.',
  school: 'Write the name of the school that candidates are applying to.',
  description: 'Describe the job title and main responsibilities. Give a comprehensive overview of the job, including daily tasks, team dynamics, and culture. Include information about benefits and other perks.',
  type: 'Specify the employment type (e.g., full-time, part-time, contract)',
  specialism: 'This is used to help candidates filter for positions and different school ages.',
  location: 'Provide information about the job location, mention any travel requirements in the description above if applicable.',
  sublocation: 'You can provide the city name here or leave this field blank.',
  salary: 'The salary range for the job. Additonal compensation can be written in the description.',
  expiry: 'This date is when the job posting will expire. Note after the expiry date the job will be gone from the jobs board.',
  poster: 'Write the email that people can contact to apply for the job.',
  plus: 'The number is your advert credit. Each credit allows you to post one job advertisement. Click the coins to add more credit. The plus allows you to create a job advertisement.'
}


// Values for location
export const locations = [
  'Anhui',
  'Beijing',           // Municipality
  'Chongqing',         // Municipality
  'Fujian',
  'Gansu',
  'Guangdong',
  'Guangxi',           // Autonomous region
  'Guizhou',
  'Hainan',
  'Hebei',
  'Heilongjiang',
  'Henan',
  'Hubei',
  'Hunan',
  'Inner Mongolia',    // Autonomous region
  'Jiangsu',
  'Jiangxi',
  'Jilin',
  'Liaoning',
  'Ningxia',           // Autonomous region
  'Qinghai',
  'Shaanxi',
  'Shandong',
  'Shanghai',          // Municipality
  'Shanxi',
  'Sichuan',
  'Tianjin',           // Municipality
  'Tibet',             // Autonomous region
  'Xinjiang',          // Autonomous region
  'Yunnan',
  'Zhejiang',
  'Hong Kong',         // Special Administrative Region
  'Macau',
  'Other'              // Special Administrative Region
];

export const sublocations = {
  'Anhui': ['Hefei', 'Wuhu', 'Bengbu', 'Ma\'anshan', 'other'],
  'Beijing': ['Beijing', 'other'],  // Municipality
  'Chongqing': ['Chongqing', 'other'],  // Municipality
  'Fujian': ['Fuzhou', 'Xiamen', 'Quanzhou', 'other'],
  'Gansu': ['Lanzhou', 'Tianshui', 'Baiyin', 'other'],
  'Guangdong': ['Guangzhou', 'Shenzhen', 'Dongguan', 'other'],
  'Guangxi': ['Nanning', 'Guilin', 'Liuzhou', 'other'],  // Autonomous region
  'Guizhou': ['Guiyang', 'Zunyi', 'Anshun', 'other'],
  'Hainan': ['Haikou', 'Sanya', 'other'],
  'Hebei': ['Shijiazhuang', 'Tangshan', 'Baoding', 'other'],
  'Heilongjiang': ['Harbin', 'Qiqihar', 'Mudanjiang', 'other'],
  'Henan': ['Zhengzhou', 'Luoyang', 'Kaifeng', 'other'],
  'Hubei': ['Wuhan', 'Yichang', 'Xiangyang', 'other'],
  'Hunan': ['Changsha', 'Zhuzhou', 'Xiangtan', 'other'],
  'Inner Mongolia': ['Hohhot', 'Baotou', 'Chifeng', 'other'],  // Autonomous region
  'Jiangsu': ['Nanjing', 'Suzhou', 'Wuxi', 'other'],
  'Jiangxi': ['Nanchang', 'Jiujiang', 'Ganzhou', 'other'],
  'Jilin': ['Changchun', 'Jilin City', 'Siping', 'other'],
  'Liaoning': ['Shenyang', 'Dalian', 'Anshan', 'other'],
  'Ningxia': ['Yinchuan', 'Shizuishan', 'Wuzhong', 'other'],  // Autonomous region
  'Qinghai': ['Xining', 'Haidong', 'other'],
  'Shaanxi': ['Xi\'an', 'Xianyang', 'Baoji', 'other'],
  'Shandong': ['Jinan', 'Qingdao', 'Yantai', 'other'],
  'Shanghai': ['Shanghai', 'other'],  // Municipality
  'Shanxi': ['Taiyuan', 'Datong', 'Changzhi', 'other'],
  'Sichuan': ['Chengdu', 'Mianyang', 'Leshan', 'other'],
  'Tianjin': ['Tianjin', 'other'],  // Municipality
  'Tibet': ['Lhasa', 'Shigatse', 'Nyingchi', 'other'],  // Autonomous region
  'Xinjiang': ['Urumqi', 'Kashgar', 'Korla', 'other'],  // Autonomous region
  'Yunnan': ['Kunming', 'Dali', 'Lijiang', 'other'],
  'Zhejiang': ['Hangzhou', 'Ningbo', 'Wenzhou', 'other'],
  'Hong Kong': ['Hong Kong', 'other'],  // Special Administrative Region
  'Macau': ['Macau', 'other'],  // Special Administrative Region
  'Taiwan': ['Taipei', 'Kaohsiung', 'Taichung', 'other'],  // Special status
  'Other': ['Other']
};

export const cityInformation = [
  {
    id: 0,
    name: 'Beijing',
    coordinates: [39.9075, 116.39723],
    info: 'Capital of China',
    costOfLiving: 'High',
    shortSummary: 'Political and cultural heart of China, famous for its historic sites.',
    longIntro: 'Beijing is the capital and a major cultural and political center of China. It is home to historic landmarks such as the Forbidden City, Tiananmen Square, and nearby sections of the Great Wall. The city is bustling with modern development while preserving its rich heritage. The cost of living is high due to its status as the national capital.'
  },
  {
    id: 1,
    name: 'Shanghai',
    coordinates: [31.2304, 121.4737],
    info: 'Largest city in China',
    costOfLiving: 'High',
    shortSummary: 'China’s global financial hub with a mix of modern skyscrapers and colonial architecture.',
    longIntro: 'Shanghai is a global financial hub and the largest city in China, known for its modern skyline, the Bund waterfront, and vibrant nightlife. It’s a major center for business, culture, and commerce. The cost of living is high, but it offers unparalleled opportunities and a fast-paced lifestyle.'
  },
  {
    id: 2,
    name: 'Guangzhou',
    coordinates: [23.1291, 113.2644],
    info: 'Major port city in southern China',
    costOfLiving: 'Medium',
    shortSummary: 'One of China’s key trade and transportation hubs, with a rich Cantonese heritage.',
    longIntro: 'Guangzhou, a bustling metropolis in southern China, is famous for its role as a major trade hub and for its Cantonese cuisine. It has a rich history and is known for its annual Canton Fair, a major trade expo. The cost of living is more affordable compared to cities like Beijing and Shanghai.'
  },
  {
    id: 3,
    name: 'Shenzhen',
    coordinates: [22.5429, 114.0596],
    info: 'Fast-growing tech hub',
    costOfLiving: 'High',
    shortSummary: 'A booming tech and innovation hub known for its rapid development.',
    longIntro: 'Shenzhen is a modern metropolis that links Hong Kong to mainland China. It is one of the fastest-growing cities in the world, driven by its booming tech industry. Home to giants like Tencent, Shenzhen is a key part of China’s innovation economy. The city offers a high standard of living, but also comes with a high cost.'
  },
  {
    id: 4,
    name: 'Chengdu',
    coordinates: [30.5728, 104.0668],
    info: 'Capital of Sichuan province, known for spicy food',
    costOfLiving: 'Medium',
    shortSummary: 'Known for its laid-back lifestyle, spicy cuisine, and panda sanctuaries.',
    longIntro: 'Chengdu is the capital of Sichuan province, famous for its relaxed lifestyle and spicy food. The city is a growing tech hub and a popular tourist destination for its panda breeding centers. Despite its growth, the cost of living remains affordable, especially in comparison to cities on the eastern coast.'
  },
  {
    id: 5,
    name: 'Chongqing',
    coordinates: [29.5638, 106.5505],
    info: 'Mountainous megacity in southwest China',
    costOfLiving: 'Medium',
    shortSummary: 'A megacity surrounded by mountains, famous for hotpot and rapid urbanization.',
    longIntro: 'Chongqing is one of China’s four direct-controlled municipalities. It is known for its mountainous terrain, hotpot cuisine, and rapid urban development. The Yangtze River runs through the city, making it a key transportation and logistics hub. Living costs here are moderate compared to coastal megacities.'
  },
  {
    id: 6,
    name: 'Xi\'an',
    coordinates: [34.3416, 108.9398],
    info: 'Home of the Terracotta Warriors',
    costOfLiving: 'Medium',
    shortSummary: 'A historical city, once the capital of ancient dynasties and home to the Terracotta Army.',
    longIntro: 'Xi’an is a city steeped in history, once serving as the capital for several ancient Chinese dynasties. It is most famous for the Terracotta Army, a UNESCO World Heritage Site. The city offers a blend of ancient history and modern development, with relatively affordable living costs.'
  },
  {
    id: 7,
    name: 'Hangzhou',
    coordinates: [30.2741, 120.1551],
    info: 'Famous for West Lake and tech industry',
    costOfLiving: 'High',
    shortSummary: 'A scenic city with a booming tech industry, known for its picturesque West Lake.',
    longIntro: 'Hangzhou is the capital of Zhejiang province and one of China’s most scenic cities, thanks to its famous West Lake. It has also become a tech powerhouse, home to Alibaba and other major companies. The city offers a high quality of life but with relatively high living costs.'
  },
  {
    id: 8,
    name: 'Wuhan',
    coordinates: [30.5928, 114.3055],
    info: 'Capital of Hubei province, a central transportation hub',
    costOfLiving: 'Medium',
    shortSummary: 'A key city in central China, known for its role as a transportation and logistics hub.',
    longIntro: 'Wuhan, located in central China, is the capital of Hubei province. It is an important transportation hub due to its location at the confluence of the Yangtze and Han rivers. The city has a rich history and has become a center for education and industry. The cost of living is moderate compared to coastal cities.'
  },
  {
    id: 9,
    name: 'Nanjing',
    coordinates: [32.0603, 118.7969],
    info: 'Former capital and cultural center',
    costOfLiving: 'Medium',
    shortSummary: 'A city with a rich history, once the capital of several Chinese dynasties.',
    longIntro: 'Nanjing, the capital of Jiangsu province, is one of China’s most historically significant cities, having served as the capital for several Chinese dynasties. It offers a mix of ancient architecture and modern amenities. Living costs are moderate, and the city is known for its vibrant cultural scene.'
  },
  {
    id: 10,
    name: 'Tianjin',
    coordinates: [39.3434, 117.3616],
    info: 'Coastal city near Beijing, important port',
    costOfLiving: 'Medium',
    shortSummary: 'A major port city, serving as a gateway to northern China’s economy.',
    longIntro: 'Tianjin is one of China’s largest coastal cities, located near Beijing. It is an important industrial and port city with a significant role in China’s northern economy. The city features a blend of European and Chinese architecture, a remnant of its past as a foreign concession city.'
  },
  {
    id: 11,
    name: 'Suzhou',
    coordinates: [31.2983, 120.5832],
    info: 'Known for classical gardens and canals',
    costOfLiving: 'Medium',
    shortSummary: 'Famous for its classical gardens, canals, and silk industry.',
    longIntro: 'Suzhou is known for its beautiful classical gardens and picturesque canals, earning it the nickname "Venice of the East." It is also an important industrial center and a part of the Greater Shanghai area. Suzhou offers a peaceful environment with moderate living costs.'
  },
  {
    id: 12,
    name: 'Harbin',
    coordinates: [45.8038, 126.5350],
    info: 'Known for its ice festivals, capital of Heilongjiang',
    costOfLiving: 'Low',
    shortSummary: 'A city in northeast China famous for its winter ice festivals and Russian influence.',
    longIntro: 'Harbin, the capital of Heilongjiang province, is known for its cold winters and the world-famous Harbin International Ice and Snow Sculpture Festival. The city has a strong Russian influence, seen in its architecture and culture. Living costs are relatively low due to its remote location.'
  },
  {
    id: 13,
    name: 'Zhengzhou',
    coordinates: [34.7473, 113.6249],
    info: 'Major transportation hub in central China',
    costOfLiving: 'Medium',
    shortSummary: 'A key transportation hub with growing economic significance in central China.',
    longIntro: 'Zhengzhou, the capital of Henan province, is a major transportation hub in central China. The city has a rapidly growing economy and plays a significant role in manufacturing and logistics. Living costs remain affordable, with the city offering a balance between urban development and historical sites.'
  },
  {
    id: 14,
    name: 'Changsha',
    coordinates: [28.2282, 112.9388],
    info: 'Capital of Hunan province, known for its spicy food',
    costOfLiving: 'Medium',
    shortSummary: 'Known for its spicy cuisine and as a historical and cultural hub of southern China.',
    longIntro: 'Changsha, the capital of Hunan province, is known for its rich cultural heritage and its famous spicy food. The city is growing rapidly in terms of economy and infrastructure, and offers a lower cost of living compared to the major eastern cities.'
  },
  {
    id: 15,
    name: 'Qingdao',
    coordinates: [36.0671, 120.3826],
    info: 'Coastal city known for Tsingtao beer',
    costOfLiving: 'Medium',
    shortSummary: 'A coastal city with a German heritage, known for its beaches and Tsingtao beer.',
    longIntro: 'Qingdao is a coastal city in Shandong province, famous for its German-style architecture, beaches, and the internationally renowned Tsingtao beer. The city has a laid-back atmosphere, making it a popular destination for both tourists and expats. The cost of living is moderate compared to larger cities.'
  },
  {
    id: 16,
    name: 'Dalian',
    coordinates: [38.9140, 121.6147],
    info: 'Port city in northeastern China, known for beaches',
    costOfLiving: 'Medium',
    shortSummary: 'A major port city known for its pleasant climate, beaches, and Russian history.',
    longIntro: 'Dalian is a key port city in northeastern China, known for its scenic coastline, beaches, and pleasant climate. It has a rich history, with Russian and Japanese influences. The city is an important center for shipping and trade, with a moderate cost of living.'
  },
  {
    id: 17,
    name: 'Fuzhou',
    coordinates: [26.0745, 119.2965],
    info: 'Coastal city, capital of Fujian province',
    costOfLiving: 'Medium',
    shortSummary: 'A coastal city with a rich history, known for its tea production and architecture.',
    longIntro: 'Fuzhou, the capital of Fujian province, is a coastal city with a rich history. It is known for its tea culture and traditional Chinese architecture. The city is growing as a hub for trade and logistics, with affordable living costs.'
  },
  {
    id: 18,
    name: 'Shenyang',
    coordinates: [41.8057, 123.4315],
    info: 'Capital of Liaoning province, major industrial city',
    costOfLiving: 'Medium',
    shortSummary: 'An important industrial city with a mix of historical and modern attractions.',
    longIntro: 'Shenyang, the capital of Liaoning province, is a major industrial city in northeastern China. It has a rich history, particularly as the home of the Qing dynasty’s first emperor. The city has a well-developed manufacturing sector, and its cost of living is moderate compared to other industrial hubs.'
  }
];
