import React from 'react';
import './HeaderFooter.css'

const Footer = () => {
  return (
    <footer>
      <div className="footer-container">
        <div className="footer-content">
          <p>&copy; {new Date().getFullYear()} chinateachingjobs.co.uk</p>
          <ul className="footer-links">
            <li><a href="/">Home</a></li>
            <li><a href="/about">About</a></li>
            <li><a href="/contact">Contact</a></li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
