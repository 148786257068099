import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { useParams } from 'react-router-dom';
import BackButton from '../../components/BackButton';
import Spinner from '../../components/Spinner'
import Header from '../../components/header';
import Footer from '../../components/footer';
import './Actions.css'
import { BACKEND_DOMAIN } from '../../constants'


const ShowJob = () => {
  const [job, setJob] = useState({});
  const [loading, setLoading] = useState(false);
  const { id } = useParams()
  // const { token } = useAuth();


  useEffect(() => {
    setLoading(true);
    axios.get(`${BACKEND_DOMAIN}/jobs/${id}`
    )
      .then((response) => {
        setJob(response.data);
        setLoading(false)
      })
      .catch((error) => {
        console.log(error);
        setLoading(false)
      })
  }, [])

  const formattedDate = (date) => {
    return (
      new Date(date).toLocaleDateString('en-GB', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      })
    )
  };

  return (
    <div className="app-home">
      <Header />
      <div className='content-container'>
        {loading ? (
          <Spinner />
        ) : (
          <div className='background-container'>
            <div className='action-content'>
              <div className='title-container'>
                <BackButton />
                <h1 className='title-text'>Job Information</h1>
              </div>
              <div className='detail-group'>
                <span>Position</span>
                <span>{job.position}</span>
              </div>
              <div className='detail-group'>
                <span>School</span>
                <span>{job.school}</span>
              </div>
              <div className='detail-group'>
                <span>Description</span>
                <span>{job.description}</span>
              </div>
              <div className='detail-group'>
                <span>Type</span >
                <span>{job.type}</span>
              </div>
              <div className='detail-group'>
                <span>Location</span >
                <span>{job.location}</span>
              </div>
              <div className='detail-group'>
                <span>City</span >
                <span>{job.sublocation}</span>
              </div>
              <div className='detail-group'>
                <span>Salary</span >
                <span>¥{job.salaryMin} - ¥{job.salaryMax}</span>
              </div>
              <div className='detail-group'>
                <span>Contact</span>
                <span>{job.contactEmail}</span>
              </div>
              <div className='detail-group'>
                <span>Creation Date</span>
                <span>{formattedDate(job.createdAt)}</span>
              </div>
              <div className='detail-group'>
                <span>Last Updated Date</span>
                <span>{formattedDate(job.updatedAt)}</span>
              </div>
            </div>
          </div>
        )}
      </div>
      <Footer />
    </div>
  )
}

export default ShowJob