
import Header from '../../components/header';
import Footer from '../../components/footer';
// src/pages/LoginPage.js
import { useState } from 'react';
import { useAuth } from '../../context/AuthContext';
import { useNavigate, useParams } from 'react-router-dom';
import './Login.css'

function ResetPassword() {
  const { token } = useParams();
  const { resetPassword } = useAuth();
  const navigate = useNavigate();

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');


  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
        // Attempt to send email to forgot password
        await resetPassword( token, password );
        navigate('/post')
    } catch (error) {
        if (error.response && error.response.status === 404) {
            // setError('Email not found. Would you like to register?');
        } else {
            // setError('Invalid email or password');
            console.error('Login failed', error);
        }
    }
};

  return (
    <div className="app-home">
      <Header />
      <div className='content-container'>
        <div className='center-container'>
          <div className="login-container">
            <h1>Reset Password</h1>
            <form className="login-form" onSubmit={handleSubmit}>
                <label htmlFor="password">New Password:</label>
                <input
                  type="password"
                  id="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
                <label htmlFor="confirmPassword">Confirm New Password:</label>
                <input
                  type="password"
                  id="confirmPassword"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  required
                />
              <button type="submit">Reset Password</button>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default ResetPassword;
