import React, { useState, useEffect } from 'react'
import axios from 'axios';
import BackButton from '../../components/BackButton';
import Spinner from '../../components/Spinner'
import { useNavigate, useParams } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import Header from '../../components/header';
import Footer from '../../components/footer';
import './Actions.css'
import { useAuth } from '../../context/AuthContext';
import { minOptions, maxOptions, locations, sublocations, specialisms, instructions } from '../../constants.js'
import { RxQuestionMarkCircled } from "react-icons/rx";
import InfoModal from '../../components/InfoModal'
import { BACKEND_DOMAIN } from '../../constants'


const EditJob = () => {
  const [showModal, setShowModal] = useState(false);
  const [infoText, setInfoText] = useState('');
  const [position, setPosition] = useState('');
  const [school, setSchool] = useState('');
  const [description, setDescription] = useState('');
  const [type, setType] = useState('');
  const [location, setLocation] = useState('');
  const [sublocation, setSublocation] = useState('');
  const [specialism, setSpecialism] = useState('');
  const [contactEmail, setcontactEmail] = useState('');
  const [salaryMin, setSalaryMin] = useState(minOptions[0]);
  const [salaryMax, setSalaryMax] = useState(maxOptions[maxOptions.length - 1]);
  const [expiryDate, setExpiryDate] = useState('');
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const { token } = useAuth();
  

  const { id } = useParams()

  const cities = location ? sublocations[location] : [];

  useEffect(() => {
    setLoading(true)
    axios
      .get(`${BACKEND_DOMAIN}/jobs/${id}`
      )
      .then((response) => {
        setPosition(response.data.position);
        setSchool(response.data.school);
        setDescription(response.data.description);
        setType(response.data.type);
        setLocation(response.data.location);
        setSublocation(response.data.sublocation);
        setSalaryMin(response.data.salaryMin);
        setSalaryMax(response.data.salaryMax);
        setExpiryDate(response.data.expiryDate);
        setSpecialism(response.data.specialism);
        setcontactEmail(response.data.contactEmail)
        setLoading(false)
      })
      .catch((error) => {
        setLoading(false);
        alert('An error happened. Please check the console.');
        console.log(error)
      })
  }, [])

  const handleEditJob = () => {
    const data = {
      position,
      school,
      description,
      type,
      specialism,
      contactEmail,
      location,
      sublocation,
      salaryMin,
      salaryMax,
      expiryDate
    }
    setLoading(true);
    axios
      .patch(`${BACKEND_DOMAIN}/jobs/${id}`, data, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'ngrok-skip-browser-warning': 'true'
        }
      })
      .then(() => {
        setLoading(false);
        enqueueSnackbar('Job edited successfully', { variant: 'success' })
        navigate('/post');
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar('Error', { variant: 'error' })
        setLoading(false)
      })
  }


  const openInfoModal = (info) => {
    setInfoText(instructions[info])
    setShowModal(true)
 }

  return (
    <div className="app-home">
      <Header />
      <div className='content-container'>
        <div className='background-container'>
          {loading ? <Spinner /> : ''}
          <div className='action-content'>
            <div className='title-container'>
              <BackButton />
              <h1 className='title-text'>Edit Job</h1>
            </div>
            <div className='form-group'>
              <label className=''>Position <RxQuestionMarkCircled className='info-modal' onClick={() => openInfoModal('position')} /> </label>
              <input
                type='text'
                value={position}
                onChange={(e) => setPosition(e.target.value)}
                className=''
              />
            </div>
            <div className='form-group'>
              <label className=''>School <RxQuestionMarkCircled className='info-modal' onClick={() => openInfoModal('school')} /> </label>
              <input
                type='text'
                value={school}
                onChange={(e) => setSchool(e.target.value)}
                className=''
              />
            </div>
            <div className='form-group'>
              <label className='label-text'>Description <RxQuestionMarkCircled className='info-modal' onClick={() => openInfoModal('description')} /> </label>
              <textarea
                type='text'
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                className='description-edit'
              />
            </div>
            <div className='form-group'>
              <label className=''>Type <RxQuestionMarkCircled className='info-modal' onClick={() => openInfoModal('type')} /> </label>
              <select value={type} onChange={(e) => setType(e.target.value)}>
                <option value="">Select Job Type</option>
                <option value="Full-time">Full-time</option>
                <option value="Part-time">Part-time</option>
                <option value="Internship">Internship</option>
                <option value="Short contract/seasonal">Internship</option>
              </select>
            </div>
            <div className='form-group'>
              <label>Specialism: <RxQuestionMarkCircled className='info-modal' onClick={() => openInfoModal('specialism')} /> </label>
              <select value={specialism} onChange={(e) => setSpecialism(e.target.value)}>
                <option value="">Select Specialism</option>
                {specialisms.map((special, index) => (
                  <option key={index} value={special}>{special}</option>
                ))}
              </select>
            </div>
            <div className='form-group'>
            <div className='location-container'>
                <label>Location: <RxQuestionMarkCircled className='info-modal' onClick={() => openInfoModal('location')} /></label>
                <label className='extra-margin-right'></label>
                <select value={location} onChange={(e) => setLocation(e.target.value)}>
                  <option value="">Select Job Location</option>
                  {locations.map((location, index) => (
                    <option key={index} value={location}>{location}</option>
                  ))}
                </select>
                <label className='extra-extra-margin-right'></label>
                <label>City: <RxQuestionMarkCircled className='info-modal' onClick={() => openInfoModal('sublocation')} /></label>
                <label className='extra-margin-right'></label>
                <select
                  value={sublocation}
                  onChange={(e) => setSublocation(e.target.value)}
                  disabled={!location} // Disable if no province is selected
                >
                  <option value="">Select city</option>
                  {cities.map((city, index) => (
                    <option key={index} value={city}>{city}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className='form-group'>
              <label>Salary Range: <RxQuestionMarkCircled className='info-modal' onClick={() => openInfoModal('salary')} /> </label>
              <div className='salary-container'>
                <label className='extra-margin-right'>
                  Min Salary:
                  <div className='currency-input'>
                    <span>¥</span>
                    <input
                      type='number'
                      value={salaryMin}
                      onChange={(e) => setSalaryMin(e.target.value)}
                    />
                    <span> - </span>
                  </div>
                </label>
                <label>
                  Max Salary:
                  <div className='currency-input'>
                    <span>¥</span>
                    <input
                      type='number'
                      value={salaryMax}
                      onChange={(e) => setSalaryMax(e.target.value)}
                    />
                  </div>
                </label>
              </div>
              <div className='form-group'>
                <label>Contact email: <RxQuestionMarkCircled className='info-modal' onClick={() => openInfoModal('poster')} /> </label>
                <input
                  type='text'
                  value={contactEmail}
                  onChange={(e) => setcontactEmail(e.target.value)}
                />
              </div>
              <button className='submit-button' onClick={handleEditJob}>
                Save
              </button>
            </div>
          </div>
        </div>
        {
                showModal && (
                    <InfoModal info={infoText} onClose={()=> {
                        setShowModal(false)}}/>
                )
            }
      </div>
      <Footer />
    </div>
  )
}

export default EditJob