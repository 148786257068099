import React, { useState } from 'react';
import { minOptions, locations, jobTypes, specialisms } from '../constants.js'

const JobsFilter = ({ onFilterChange, isNarrow, isVisible }) => {
  const [filters, setFilters] = useState({
    jobTypes: [],
    locations: '',
    specialisms: [],
    minSalary: '',
    maxSalary: '',
    datePosted: '',
  });

  const handleCheckboxChange = (filterType, value) => {
    setFilters((prevFilters) => {
      const updatedFilterValues = prevFilters[filterType].includes(value)
        ? prevFilters[filterType].filter((item) => item !== value)
        : [...prevFilters[filterType], value];

      const updatedFilters = { ...prevFilters, [filterType]: updatedFilterValues };
      onFilterChange(updatedFilters);
      return updatedFilters;
    });
  };

  const handleDropdownChange = (name, value) => {
    let updatedFilters
    if(name === 'specialisms' || name === 'jobTypes'){
      updatedFilters = { ...filters, [name]: [value] };
    }
    else {
      updatedFilters = { ...filters, [name]: value };
    }
    console.log(updatedFilters)
    setFilters(updatedFilters);
    onFilterChange(updatedFilters);
  };

  const dateOptions = ['Last 24 hours', 'Last 7 days', 'Last 30 days', 'Any time'];

  return (
    <div className={`filter ${isVisible ? 'visible' : 'hidden'}`}>
      <h3 className="filter-title">Filter your search</h3>
      <div className="filter-narrow">
      <div className="divider"></div>
      {/* Job Type Filter */}
      {isNarrow ? (
        <div className="filter-section">
          <div className="filter-dropdown">
          <select
            value={filters.jobTypes[0] || ''}
            onChange={(e) => handleDropdownChange('jobTypes', e.target.value)}
          >
            <option value="">Select Job Type</option>
            {jobTypes.map((type) => (
              <option key={type} value={type}>
                {type}
              </option>
            ))}
          </select>
          </div>
        </div>
      ) : (
        <div className="filter-section">
          <h4>Type of Job</h4>
          {jobTypes.map((type) => (
            <label key={type} className="filter-checkbox">
              <input
                type="checkbox"
                checked={filters.jobTypes.includes(type)}
                onChange={() => handleCheckboxChange('jobTypes', type)}
              />
              {type}
            </label>
          ))}
        </div>
      )}

      <div className="divider"></div>
      {/* Specialism Filter */}
      {isNarrow ? (
        <div className="filter-section">
          <div className="filter-dropdown">
          <select
            value={filters.specialisms[0] || ''}
            onChange={(e) => handleDropdownChange('specialisms', e.target.value)}
          >
            <option value="">Select Specialism</option>
            {specialisms.map((specialism) => (
              <option key={specialism} value={specialism}>
                {specialism}
              </option>
            ))}
          </select>
          </div>
        </div>
      ) : (
        <div className="filter-section">
          <h4>Specialism</h4>
          {specialisms.map((specialism) => (
            <label key={specialism} className="filter-checkbox">
              <input
                type="checkbox"
                checked={filters.specialisms.includes(specialism)}
                onChange={() => handleCheckboxChange('specialisms', specialism)}
              />
              {specialism}
            </label>
          ))}
        </div>
      )}

      <div className="divider"></div>

      {/* Salary Filter */}
      <div className="filter-section">
        {!isNarrow && <h4>Salary</h4>}
        <div className="filter-dropdown">
          <label>
            <select name="minSalary" value={filters.minSalary} onChange={handleDropdownChange}>
              <option value="">Select Min Salary</option>
              {minOptions.map((amount) => (
                <option key={amount} value={amount}>
                  ¥{amount}
                </option>
              ))}
            </select>
          </label>
        </div>
      </div>
      <div className="divider"></div>
      {/* Date Posted Filter */}
      <div className="filter-section">
        {!isNarrow && <h4>Date Posted</h4>}
        <div className="filter-dropdown">
          <select name="datePosted" value={filters.datePosted} onChange={handleDropdownChange}>
            <option value="">Select Date</option>
            {dateOptions.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className="divider"></div>

      {/* Location Filter */}
      <div className="filter-section">
        {!isNarrow && <h4>Location</h4>}
        <div className="filter-dropdown">
        <select name="location" value={filters.location} onChange={handleDropdownChange}>
            <option value="">Select Location</option>
            {locations.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
          </div>
          <div className="divider-end"></div>
      </div>
      <div className="narrow-divider"></div>
      </div>
    </div>

  );
};

export default JobsFilter;
