import React, { useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import axios from 'axios';
import Header from '../../components/header';
import Footer from '../../components/footer';
import { useAuth } from '../../context/AuthContext';
import { BACKEND_DOMAIN } from '../../constants'

import './payment.css'

// Load your Stripe publishable key
// pk_live_51PgVUWRoItGHiYcu7tKK2sYEaG9iCtY2KZ2e7o6RgqafRv90ay82DC5YDawg1LQmUdol1vHNrbDdIy6d1g6DIaVv00t96I6hmP
// 
const stripePromise = loadStripe('pk_live_51PgVUWRoItGHiYcu7tKK2sYEaG9iCtY2KZ2e7o6RgqafRv90ay82DC5YDawg1LQmUdol1vHNrbDdIy6d1g6DIaVv00t96I6hmP'); // Initialize Stripe Publishable Key


const CreateCheckoutSession = () => {
  const { user, creditPurchase, token } = useAuth();

  useEffect(() => {
    const handleRedirectToCheckout = async (token) => {
      try {
        const stripe = await stripePromise;
        const response = await axios.post(`${BACKEND_DOMAIN}/jobs/create-checkout-session`, { userID: user._id, amount: creditPurchase }, { headers: {
          Authorization: `Bearer ${token}`
        },});
        const { sessionId } = response.data;
        const result = await stripe.redirectToCheckout({ sessionId });

        if (result.error) {
          console.error('Stripe Checkout Error:', result.error.message);
        }
      } catch (error) {
        console.error('Error creating Stripe checkout session:', error);
      }
    };

    handleRedirectToCheckout();
  }, []);

  return (
    <div>
      <Header />
      {/* container for everything within header */}
      <div className='content-container'>
        {/* container for background image */}
        <div className='background-container'>
          <div className='action-content'>
            <h2>Redirecting to payment...</h2>
            <p>If you are not redirected, please check your internet connection.</p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default CreateCheckoutSession;
