import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom'

import '../screens/home.css'

// Jobs board for the home page

const JobsBoard = ({ jobs, setScrollPosition, scrollPosition }) => {
  const navigate = useNavigate()

  const handleJobClick = (job) => {
      navigate(`/jobs`, { state: { job } });
  };

  const formattedDate = (date) => {
    return (
      new Date(date).toLocaleDateString('en-GB', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      })
    )
  };

  function isNewJob(createdAt) {
    const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
    const createdDate = new Date(createdAt);
    const now = new Date();
    return now - createdDate < oneDay;
  }

  useEffect(() => {
    const scrollableDiv = document.querySelector('.scrollable-container');

    if (scrollableDiv) {
      const handleScroll = () => {
        const scrollTop = scrollableDiv.scrollTop;
        setScrollPosition(scrollTop);
      };

      scrollableDiv.addEventListener('scroll', handleScroll);

      return () => {
        scrollableDiv.removeEventListener('scroll', handleScroll);
      };
    }
  }, []);

  return (
    <div className="jobs-board scrollable-container">
      {jobs.map((job, index) => (
        <div key={index} className="job-item" onClick={() => handleJobClick(job)}>
          <div className='title-container'>
          <h3>{job.position}</h3>
          {isNewJob(job.createdAt) && <span className="job-new-badge">New</span>}
          </div>
          <div className='title-container'>
            <p>{job.school}</p>
            <div>
            <p>{job.location}</p>
            <p>{job.sublocation}</p>
            </div>
          </div>
          <div className="job-badge">{job.type}</div>
          <p className='description-short'>&bull;  {job.description}</p>
          <p className='created-date'>Posted on: {formattedDate(job.createdAt)}</p>
        </div>
      ))}
    </div>
  );
};

export default JobsBoard;
