// src/context/AuthContext.js
import { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';
import { useSnackbar } from 'notistack'
import { BACKEND_DOMAIN } from '../constants'


const AuthContext = createContext();


export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(() => localStorage.getItem('token'));
  const [errorMessage, setErrorMessage] = useState('')
  const [tokenCount, setTokenCount] = useState(0);
  const [creditPurchase, setCreditPurchase] = useState()

  useEffect(() => {
    console.log("user:"+user)
    const fetchUserData = async (token) => {
      try {
        const response = await axios.get(`${BACKEND_DOMAIN}/jobs/profile`, {
          headers: {
            Authorization: `Bearer ${token}`
          },
        });
        setUser(response.data.user);
        console.log("response user:"+response.data.user)
      } catch (error) {
        console.error('Failed to fetch user data:', error.response ? error.response.data : error.message);
      }
    };
    if (token) {
      fetchUserData(token);
    }
  }, [token]);  // Now it will also trigger if `token` changes


  const login = async (email, password) => {
    try {
      const response = await axios.post(`${BACKEND_DOMAIN}/auth/login`, { email, password },
      );
      setUser(response.data.user);
      setToken(response.data.token);
      setTokenCount(response.data.user.tokenCount);
      localStorage.setItem('token', response.data.token);
      setErrorMessage('')
      enqueueSnackbar('Logged in', { variant: 'success' })
    } catch (error) {
      setErrorMessage(error.response.data.msg)
      enqueueSnackbar('Failed to login', { variant: 'error' })
      setTimeout(() => {
        setErrorMessage("");
      }, 3000);
    }
  };

  const register = async (name, email, password, tokenCount = 0) => {
    try {
      const response = await axios.post(`${BACKEND_DOMAIN}/auth/register`, { name, email, password, tokenCount });
      setUser(response.data.user);
      setToken(response.data.token);
      setTokenCount(response.data.user.tokenCount);
      localStorage.setItem('token', response.data.token);
      setErrorMessage('');
      enqueueSnackbar('Registered account', { variant: 'success' });
    } catch (error) {
      setErrorMessage(error.response.data.msg)
      enqueueSnackbar('Failed to register', { variant: 'error' })
      setTimeout(() => {
        setErrorMessage("");
      }, 3000);
    }
  };

  const logout = () => {
    setUser(null);
    setToken(null);
    localStorage.removeItem('token');
    setErrorMessage('')
  };

  const forgotPassword = async (email) => {
    try {
      await axios.post(`${BACKEND_DOMAIN}/auth/forgot-password`, { email });
      enqueueSnackbar('Password reset email sent. Check email.', { variant: 'success' })
    } catch (error) {
      console.error('Forgot password failed', error);
      setErrorMessage(error.response.data.msg)
      setTimeout(() => {
        setErrorMessage("");
      }, 3000);
    }
  };

  const resetPassword = async (token, newPassword) => {
    try {
      await axios.post(`${BACKEND_DOMAIN}/auth/reset-password/${token}`, { password: newPassword });
      enqueueSnackbar('Password reset', { variant: 'success' })
    } catch (error) {
      console.error('Reset password failed', error);
      enqueueSnackbar('Password reset failed', { variant: 'error' })
      setErrorMessage(error.response.data.msg)
      setTimeout(() => {
        setErrorMessage("");
      }, 3000);
    }
  };

  const updateTokenCount = async (amount) => {
    try {
      const response = await axios.patch(`${BACKEND_DOMAIN}/auth/update-token-count`,
        { userId: user._id, amount },
        { headers: { 'Authorization': `Bearer ${token}` } }
      );
      setTokenCount(response.data.tokenCount);
    } catch (error) {
      setErrorMessage(error.response.data.msg)
      setTimeout(() => {
        setErrorMessage("");
      }, 3000);
    }
  };

  const sendContactEmail = async (formData) => {
    try {
      const response = await axios.post(`${BACKEND_DOMAIN}/auth/send-contact-email`, { formData },
      );
      if (response.status === 200) {
        console.log('Email sent successfully!');
        enqueueSnackbar('Message sent', { variant: 'success' })
      } else {
        enqueueSnackbar('Message failed to send', { variant: 'error' })
      }
    } catch (error) {
      console.error('Error:', error);
    }
  }


  return (
    <AuthContext.Provider value={{
      user,
      login,
      register,
      forgotPassword,
      resetPassword,
      errorMessage,
      token,
      logout,
      tokenCount,
      setTokenCount,
      updateTokenCount,
      creditPurchase,
      setCreditPurchase,
      sendContactEmail
    }}>
      {children}
    </AuthContext.Provider>
  );
};
