import { Link } from 'react-router-dom'
import { PiBookOpenTextLight } from 'react-icons/pi'
import { BiUserCircle } from 'react-icons/bi'
import { AiOutlineEdit } from 'react-icons/ai'
import { BsInfoCircle } from 'react-icons/bs'
import { MdOutlineDelete } from 'react-icons/md'
import { FaRegCalendarAlt } from "react-icons/fa";

import './Jobs.css'



const JobSingleCard = ({ job }) => {


    const formattedExpiryDate = new Date(job.expiryDate).toLocaleDateString('en-GB', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      });

    return (
        <div
            className='card'
        >
            <div className='flex-container'>
                <BiUserCircle  className='icon-red icon-large' />
                <p className='card-text'>{job.position}</p>
            </div>
            <div className='flex-container'>
                <PiBookOpenTextLight className='icon-red icon-large' />
                <p className='card-text'>{job.school}</p>
            </div>
            <div className='flex-container'>
                <FaRegCalendarAlt className='icon-red icon-large' />
                <p className='card-text'>Ends: {formattedExpiryDate}</p>
            </div>
            <div className='flex-container gap-bottom'>
                <Link to={`/jobs/details/${job._id}`}>
                    <BsInfoCircle className='icon-green icon-hover icon-large' />
                </Link>
                <Link to={`/jobs/edit/${job._id}`}>
                    <AiOutlineEdit className='icon-yellow icon-hover icon-large' />
                </Link>
                <Link to={`/jobs/delete/${job._id}`}>
                    <MdOutlineDelete className='icon-red-dark icon-hover icon-large' />
                </Link>
            </div>
            {/* {
                showModal && (
                    <JobModal job={job} onClose={()=> {
                        setShowModal(false)}}/>
                )
            } */}
        </div>
    )
}

export default JobSingleCard