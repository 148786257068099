
import Header from '../../components/header';
import Footer from '../../components/footer';
import React, { useState } from 'react';
import './ContactAbout.css'
import { useAuth } from '../../context/AuthContext';
import { useSnackbar } from 'notistack'

function Contact() {
  const { sendContactEmail  } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    enqueueSnackbar('Message sent', { variant: 'success' })
    sendContactEmail(formData)
  };

  return (
    <div className="app-home">
      <Header></Header>
      <div className='content-container'>
        <div className='contact-us-form'>
          <div className='contact-container'>
            <div className='about-column-container'>
              <h2>Contact us</h2>
              <p>
                If you wish to contact us about the website, feel free to use the contact form below.
                Please note that for job related queries or submissions the email provided in the job advert should be used.
              </p>
            </div>
            <div className='about-column-container'>
              <form onSubmit={handleSubmit}>
                <label>
                  Name:
                  <input type='text' name='name' value={formData.name} onChange={handleChange} required />
                </label>
                <label>
                  Email:
                  <input type='email' name='email' value={formData.email} onChange={handleChange} required />
                </label>
                <label>
                  Message:
                  <textarea name='message' value={formData.message} onChange={handleChange} required />
                </label>
                <button type='submit'>Submit</button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
}

export default Contact;
