import React from 'react';
import { Map, Marker } from 'pigeon-maps';
import './ChinaMap.css'; // Import the CSS file for styling
import { cityInformation } from '../constants';


const ChinaMap = ({ selectedCity, nextCity, prevCity, selectedIndex }) => {

  let position = 'nextName';
  if (selectedCity.id === selectedIndex) {
    position = 'activeName';
  }
  if (
    selectedCity.id === selectedIndex - 1 ||
    (selectedIndex === 0 && selectedCity.id === cityInformation.length - 1)
  ) {
    position = 'lastName';
  }

  return (
    <div className="map-container">
      {/* Map Component */}
      <Map center={[35.8617, 104.1954]} zoom={3.5} height={300} minZoom={3.5}
        maxZoom={4}>
        {/* Conditionally render markers and labels */}
        {selectedCity && (
          <Marker 
            key={`point-marker-${selectedCity.name}`} 
            anchor={selectedCity.coordinates}
            color={'red'}
            >
          </Marker>
        )}
      </Map>
      {/* City Navigation Arrows */}
      <div className="city-navigation">
        <button className="prev" onClick={prevCity}>
          &#9664; {/* Left arrow symbol */}
        </button>
        {/* City Name Slider */}
        <div className="city-slider">
          {cityInformation.map((city, cityIndex) => {
            let position = 'nextSlide';
            if (cityIndex === selectedIndex) {
              position = 'activeSlide';
            }
            if (cityIndex === selectedIndex - 1 || (selectedIndex === 0 && cityIndex === cityInformation.length - 1)) {
              position = 'lastSlide';
            }

            return (
              <article key={city.name} className={position}>
                <p className='city-name-text'>{city.name}</p>
              </article>
            );
          })}
        </div>
        <button className="next" onClick={nextCity}>
          &#9654; {/* Right arrow symbol */}
        </button>
      </div>
      <div>
          <p>{cityInformation[selectedIndex].longIntro}</p>
        </div>
    </div>
  );
};

export default ChinaMap;
