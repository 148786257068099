import { useState, useContext } from 'react';
import { useAuth } from '../context/AuthContext';
import { AiOutlineClose } from 'react-icons/ai';
import './modalStyles.css'
import { useNavigate } from 'react-router-dom'; 

const PaymentModal = ({ onClose }) => {
  const { creditPurchase, setCreditPurchase, tokenCount } = useAuth();
  const navigate = useNavigate();

  const options = [
    { credits: 1, price: 10 },
    { credits: 5, price: 45 },
    { credits: 10, price: 85 },
    { credits: 20, price: 160 },
  ];

  const handlePurchase = () => {
    if (creditPurchase) {
      // Handle the purchase logic here
      navigate('/jobs/create-checkout-session');
      console.log(`Purchasing ${creditPurchase} credits`);
    } else {
      alert("Please select a purchase option.");
    }
  };

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={(event) => event.stopPropagation()}>
        <button className="close-button" onClick={onClose}>
          <AiOutlineClose size={24} />
        </button>
        <p className="modal-text">
          {tokenCount === 0 && 'You currently have 0 credit.  '}
          Would you like to purchase more credit for job adverts?
        </p>
        <div className="radio-options">
          {options.map((option, index) => (
            <label key={index} className="radio-label">
              <input
                type="radio"
                name="credits"
                value={option.credits}
                onChange={() => setCreditPurchase(option.credits)}
                checked={creditPurchase === option.credits}
              />
              {option.credits} {option.credits === 1 ? 'ad' : 'ads'} for £{option.price}
            </label>
          ))}
        </div>
        <button className="purchase-button" onClick={handlePurchase}>
          Continue to payment
        </button>
      </div>
    </div>
  );
};

export default PaymentModal;
