import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Header from '../components/header';
import Footer from '../components/footer';
import JobsBoard from '../components/jobsBoard';
import JobsFilter from '../components/JobsFilter';
import { MdSort } from "react-icons/md";
import { FaAngleRight, FaAngleLeft } from "react-icons/fa";
import './home.css';
import { BACKEND_DOMAIN } from '../constants'
import Spinner from '../components/Spinner';

function Home() {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [isVisible, setIsVisible] = useState(true);

  const [filteredJobs, setFilteredJobs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1); // Store total pages
  const [isNarrow, setIsNarrow] = useState(window.innerWidth < 800)
  const [filters, setFilters] = useState({
    jobTypes: [],
    location: '',
    specialisms: [],
    minSalary: '',
    maxSalary: '',
    datePosted: ''
  });

  useEffect(() => {
    const handleResize = () => {
      setIsNarrow(window.innerWidth < 900);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  
  // Build the query string
  const queryParams = new URLSearchParams({
    ...filters,
    jobTypes: filters.jobTypes.join(','),
    specialisms: filters.specialisms.join(','),
    page: currentPage,
    limit: 10
  }).toString();

  // Fetch jobs with pagination
  const fetchJobs = (page = 1) => {
    setLoading(true);
    axios
      .get(`${BACKEND_DOMAIN}/jobs?${queryParams}`
      )
      .then((response) => {
        setFilteredJobs(response.data.data);
        setTotalPages(response.data.totalPages); // Set total pages from the response
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  // Fetch jobs for the current page
  useEffect(() => {
    fetchJobs(currentPage); 
  }, [currentPage, filters]);
  // Update the filters state with the new filter values
  const handleFilterChange = (newFilters) => {
    setFilters(newFilters);  
  };
  // Pagination controls
  const goToNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };
  const goToPreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  // hide filters with scroll
  useEffect(()=>{
    if(scrollPosition > 50){
      setIsVisible(false)
    }
    if(scrollPosition < 50){
      setIsVisible(true)
    }
  }, [scrollPosition])

  return (
    <div className="app-home">
      <Header />
      <div className="content-container">
        <div className="background-container">
          <div className={isNarrow ? 'job-container-narrow' : 'job-container-wide'}>
            {!isNarrow && 
            <div className='job-filter-container-wide'>
              <JobsFilter onFilterChange={handleFilterChange} isNarrow={isNarrow} isVisible={true} scrollPosition={0}></JobsFilter>
            </div>}
            {isNarrow && <JobsFilter onFilterChange={handleFilterChange} isNarrow={isNarrow} isVisible={isVisible} scrollPosition={scrollPosition}></JobsFilter>}
            <div className="job-list-container">
            {loading ? <Spinner /> :
              <div>
              {!isNarrow && <span className="job-sort">Sorted by earliest end date  <MdSort /></span>}
              <JobsBoard jobs={filteredJobs} setScrollPosition={setScrollPosition} scrollPosition={scrollPosition} />
              {filteredJobs.length === 0 && (
                <h1 className="jobs-empty-text">
                  No jobs match your criteria. Try adjusting your filters to see more options!
                </h1>
              )}
              {/* Pagination Controls */}
              <div className="pagination-controls">
                <button onClick={goToPreviousPage} disabled={currentPage === 1}>
                <FaAngleLeft />
                </button>
                <span>Page {currentPage} of {totalPages === 0 ? 1 : totalPages}</span>
                <button onClick={goToNextPage} disabled={currentPage === totalPages}>
                <FaAngleRight />
                </button>
                </div>
              </div>}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Home;
