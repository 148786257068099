import React, { useEffect, useState } from 'react';
import axios from 'axios';

import Header from '../components/header';
import Footer from '../components/footer';
import Spinner from '../components/Spinner';
// Icon imports
import { MdOutlineAddBox } from 'react-icons/md';
import { GrMoney } from "react-icons/gr";
import { RxQuestionMarkCircled } from "react-icons/rx";

import { instructions } from '../constants.js'
import InfoModal from '../components/InfoModal'


import JobsCards from '../components/JobsCards';
import './Post.css'
import { useAuth } from '../context/AuthContext';
// for payment processing
import { useNavigate } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js'; // Import Stripe.js
import PaymentModal from '../components/PaymentModal'
import { BACKEND_DOMAIN } from '../constants'

//pk_test_51PgVUWRoItGHiYcuX0Fpjv62CzImJL2dUwDsClfKr3EgIFflHJWdgRohMtrnPxJDFZI7G0n52yfHZczBsUPDH1Ti00NLe7wmSw
//pk_live_51PgVUWRoItGHiYcu7tKK2sYEaG9iCtY2KZ2e7o6RgqafRv90ay82DC5YDawg1LQmUdol1vHNrbDdIy6d1g6DIaVv00t96I6hmP
const stripePromise = loadStripe('pk_live_51PgVUWRoItGHiYcu7tKK2sYEaG9iCtY2KZ2e7o6RgqafRv90ay82DC5YDawg1LQmUdol1vHNrbDdIy6d1g6DIaVv00t96I6hmP'); // Initialize Stripe Publishable Key

const PostJob = () => {
  const [showPaymentModal, setShowPaymentModal] = useState(false)
  const [showInfoModal, setShowInfoModal] = useState(false)
  const [infoText, setInfoText] = useState('')
  const [jobs, setJobs] = useState([])
  const [loading, setLoading] = useState(false)
  const { token, tokenCount, updateTokenCount, setTokenCount, user } = useAuth();
  const navigate = useNavigate();


  useEffect(() => {
    setLoading(true);
    axios
        .get(`${BACKEND_DOMAIN}/jobs/my-jobs`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        })
        .then((response) => {
          setJobs(response.data.data);
          setTokenCount(response.data.tokenCount);
          setLoading(false)
        })
        .catch((error) => {
          console.log(error);
          setLoading(false)
        });
  }, [])

  const openInfoModal = (info) => {
    setInfoText(instructions[info]);
    setShowInfoModal(true);
  }

  const openPaymentModal = () => {
    setShowPaymentModal(true)
  };

  return (
    <div className="app-home">
      <Header />
      <div className='content-container'>
        <div className='background-container post-container'>
          <div className='post-title'>
            <h1 className='post-title-text'>Jobs List</h1>
            <div className='right-side-title'>
              <h1 className='post-title-text'>{tokenCount}</h1>
              <button onClick={() => { openPaymentModal() }}>
                <GrMoney className='money-button'></GrMoney>
              </button>
              <div className="post-icon-container">
                <button onClick={() => {
                  tokenCount > 0 ? navigate('/jobs/create') :
                    openPaymentModal()
                }}>
                  <MdOutlineAddBox className='add-button'></MdOutlineAddBox>
                </button>
                <RxQuestionMarkCircled className='info-modal post-question' onClick={() => openInfoModal('plus')} />
              </div>
            </div>
          </div>
          {
            loading ? (
              <Spinner />
            ) : (
              <JobsCards jobs={jobs} />
            )
          }
          <div className='center-container'>
            <button className='opening-button' onClick={() => {
              tokenCount > 0 ? navigate('/jobs/create') :
                openPaymentModal()
            }}>New Position</button>
            {
              showPaymentModal && (
                <PaymentModal onClose={() => { setShowPaymentModal(false) }} />
              )
            };
            {
              showInfoModal && (
                <InfoModal info={infoText} onClose={() => { setShowInfoModal(false) }} />
              )
            }
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
}

export default PostJob


