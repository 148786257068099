import Header from '../../components/header';
import Footer from '../../components/footer';
import './ContactAbout.css'
import { useState, useEffect } from 'react'


function About() {
  const [isNarrow, setIsNarrow] = useState(window.innerWidth < 800)

  useEffect(() => {
    const handleResize = () => {
      setIsNarrow(window.innerWidth < 800);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="app-home">
      <Header />
      <div className="content-container">
        <div className={isNarrow ? "about-container" : "about-container-wide"}>
        <div className={isNarrow ? "content" : "content-wide"}>
          <h1>About Us</h1>
          <p>
            Our platform is dedicated to helping teachers from around the world find rewarding
            teaching positions across China. Whether you're an experienced educator or just
            starting out, we aim to make the job search process simple, transparent, and tailored to your needs.
            With opportunities in cities big and small, we connect you with schools that are actively seeking
            passionate individuals to make a difference in education.
          </p>

          <p>
            We believe in the power of education and are committed to fostering a community
            where teachers can thrive both professionally and personally in China.
          </p>
        </div>
        <div className={isNarrow ? "image-section" : "image-section-wide"}>
          <img src="/images/city-skyline.jpg" alt="Chinese city skyline" className={isNarrow ? "about-image" : "about-image-wide"} />
          <img src="/images/teaching-classroom.jpg" alt="Teaching in a classroom" className={isNarrow ? "about-image" : "about-image-wide"} />
          <img src="/images/china-map.jpg" alt="Map of China" className={isNarrow ? "about-image" : "about-image-wide"} />
        </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default About;
