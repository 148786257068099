import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// Main Pages
import Home from './screens/home';
import PostJob from './screens/post';
import SingleJob from './screens/singleJob';
// Header / Footer Pages
import Life from './screens/life';
import Contact from './screens/footer/contact';
import About from './screens/footer/about';
// Actions
import CreateJob from './screens/actions/CreateJob'
import DeleteJob from './screens/actions/DeleteJob'
import EditJob from './screens/actions/EditJob'
import ShowJob from './screens/actions/ShowJob'
// Payment Screens
import CreateCheckoutSession from './screens/payment/CreateCheckoutSession'
import PaymentSuccess from './screens/payment/payment-success'
import PaymentCancel from './screens/payment/payment-cancelled'
// Login Pages
import Login from './screens/login/login';
import Register from './screens/login/register';
import ForgotPassword from './screens/login/forgot-password';
import ResetPassword from './screens/login/reset-password';

import { AuthProvider } from './context/AuthContext';
import PrivateRoute from './components/PrivateRoute';

function App() {
  
  return (
    <AuthProvider>
    <Router>
      <Routes>
        <Route path='/' element={<Home/>} />
        <Route path='/jobs' element={<SingleJob/>} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password/:token" element={<ResetPassword />} />
        <Route path='/post' element={<PrivateRoute><PostJob/></PrivateRoute>} />
        <Route path='/life' element={<Life/>} />
        <Route path='/contact' element={<Contact/>} />
        <Route path='/about' element={<About/>} />
        <Route path='/jobs/create-checkout-session' element={<PrivateRoute><CreateCheckoutSession /></PrivateRoute>} />
        <Route path='/success' element={<PaymentSuccess />} />
        <Route path='/cancelled' element={<PaymentCancel />} />

        <Route path='/jobs/create' element={<PrivateRoute><CreateJob /></PrivateRoute>} />
        <Route path='/jobs/details/:id' element={<PrivateRoute><ShowJob /></PrivateRoute>} />
        <Route path='/jobs/edit/:id' element={<PrivateRoute><EditJob /></PrivateRoute>} />
        <Route path='/jobs/delete/:id' element={<PrivateRoute><DeleteJob /></PrivateRoute>} />
      </Routes>
    </Router>
    </AuthProvider>
  );
}

export default App;
