
import Header from '../../components/header';
import Footer from '../../components/footer';
// src/pages/LoginPage.js
import { useState } from 'react';
import { useAuth } from '../../context/AuthContext';
import './Login.css'
import { useNavigate } from 'react-router-dom'

const Register = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [name, setName] = useState('');
  const { register } = useAuth();
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }
    try {
      // Attempt to register with provided email and password
      await register(name, email, password);
      navigate('/post')
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setError('Email not found. Would you like to register?');
        // Implement registration logic here if user wants to register
        // For example, call a register function in your AuthContext
        // await register(email, password);
      } else {
        setError('Email already used.');
        console.error('Login failed', error);
      }
    }
  };

  return (
    <div className="app-home">
      <Header />
      <div className='content-container'>
        <div className='center-container'>
          <div className="login-container">
            <h2>Register</h2>
            <form className="login-form" onSubmit={handleSubmit}>
              <label>Name:</label>
              <input
                type="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
              <label>Email:</label>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />

              <label>Password:</label>
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <label>Confirm Password:</label>
              <input
                type="password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
              />

              {error && <div className="error-message">{error}</div>}

              <button type="submit">Register</button>

              
            </form>
            <div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Register;
