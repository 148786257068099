import { AiOutlineClose } from 'react-icons/ai';

const InfoModal = ({ info, onClose }) => {
    return (
      <div className="modal-overlay" onClick={onClose}>
        <div className="modal-content" onClick={(event) => event.stopPropagation()}>
          <button className="close-button" onClick={onClose}>
            <AiOutlineClose size={24} />
          </button>
          <p className="modal-text">{info}</p>
        </div>
      </div>
    );
  };

export default InfoModal