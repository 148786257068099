
import Header from '../../components/header';
import Footer from '../../components/footer';
// src/pages/LoginPage.js
import { useState, useEffect } from 'react';
import { useAuth } from '../../context/AuthContext';
import './Login.css'
import { useNavigate } from 'react-router-dom'
import { Link } from 'react-router-dom'

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { login, errorMessage } = useAuth();
  const navigate = useNavigate();

  // Check for token on component mount to renavigate once logged in
  useEffect(() => {
    const storedToken = localStorage.getItem('token');
    if (storedToken) {
      navigate('/post'); // Navigate to the desired page if token exists
    }
  }, [navigate]); // Only run once on mount



  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await login(email, password);
      navigate('/post')
    } catch (error) {
      console.log('error in frontend login: '+error)
    }
  };

  return (
    <div className="app-home">
      <Header />
      <div className='content-container'>
        <div className='center-container'>
        <div className="login-container">
          <h2>Login</h2>
          <p>Login to post an advert for a job position</p>
          <form className="login-form" onSubmit={handleSubmit}>
            <label>Email:</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />

            <label>Password:</label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />

            <button type="submit">Login</button>

            {errorMessage && <div className="error-message">{errorMessage}</div>}
          </form>
          <div className='lower-button-container'>
            <Link to='/forgot-password'>
            <button className='lower-button'>Forgotten password?</button>
            </Link>
            <Link to='/register'>
            <button className='lower-button'>Register</button>
            </Link>
          </div>
        </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Login;
