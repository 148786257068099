import React, { useEffect, useState} from 'react'
import { useLocation } from 'react-router-dom';
import Header from '../components/header';
import Footer from '../components/footer';
import BackButton from '../components/BackButton'
import './singlejob.css'

// import { useAuth } from '../context/AuthContext';


const SingleJob = () => {
  const location = useLocation();
  const [isNarrow, setIsNarrow] = useState(window.innerWidth < 800)
  const { job } = location.state || {};

  useEffect(() => {
    const handleResize = () => {
      setIsNarrow(window.innerWidth < 800);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const formattedDate = (date) => {
    return (
      new Date(date).toLocaleDateString('en-GB', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      })
    )
  };

  return (
    <div className='app-home'>
      <Header />
      <div className='content-container'>
      <div className="background-container">
            <div className='single-job-content'>
              <div className='intro-container'>
                  <BackButton destination='/' />
                  <h3>{job.position}</h3>
              </div>
              <div className='school-container'>
              <h3>{job.school}</h3>
              </div>
              <div className='badge-container'>
                  <h4 className='extra-badge'>{job.type}</h4>
                  <h4 className='extra-badge'>{job.sublocation && job.sublocation} {!isNarrow && job.sublocation && " - "+job.sublocation}</h4>
                  <h4 className='extra-badge'>{isNarrow ? `¥${job.salaryMin}+`:`¥${job.salaryMin} - ¥${job.salaryMax}`}</h4>
              </div>
              <div className='description-container'>
                <p className='description-text'>{job.description}</p>
              </div>
              <div className='detail-group job-bottom-bar'>
                <h4 className='job-contact-text'>Contact: {job.contactEmail}</h4>
              </div>
              <div className='detail-group job-bottom-bar'>
                <h4>Expiry Date: {formattedDate(job.expiryDate)}</h4>
                <div>
                <h4>Posted on: {formattedDate(job.createdAt)}</h4>
                <h4>Last Updated: {formattedDate(job.updatedAt)}</h4>
                </div>
              </div>
            </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default SingleJob