import React, { useState, useEffect } from 'react';
import Header from '../components/header';
import Footer from '../components/footer';
import './Life.css';
import './home.css';
import ChinaMap from '../components/ChinaMap';
import { cityInformation } from '../constants';

function Life() {

  const [selectedIndex, setSelectedIndex] = useState(0);
  const [isNarrow, setIsNarrow] = useState(window.innerWidth < 800)

  const selectedCity = cityInformation[selectedIndex]; // Get the city by its index
  // Handle clicking the next city
  const nextCity = () => {
    setSelectedIndex((prevIndex) => (prevIndex + 1) % cityInformation.length); // Loop around to the first city
  };

  // Handle clicking the previous city
  const prevCity = () => {
    setSelectedIndex((prevIndex) => (prevIndex - 1 + cityInformation.length) % cityInformation.length); // Loop back to the last city
  };

  useEffect(() => {
    const handleResize = () => {
      setIsNarrow(window.innerWidth < 800);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div>
      <Header />
      <div className='content-container'>
        <div className='full-width-image'>
          <img src="/images/shanghai10.jpeg" alt="Shanghai" />
        </div>
        <div className='text-container'>
          <p className='life-title-text'>Life in China</p>
          <p className='life-paragraph'>
            Living in China offers a unique blend of tradition and modernity. From bustling megacities like Beijing and Shanghai
            to the serene landscapes of Yunnan and Guilin, China is a country of contrasts. Its cities are known for their
            futuristic skylines, efficient public transport, and thriving economies, while its countryside offers breathtaking
            natural beauty and a slower pace of life.
          </p>

          <p className='life-paragraph'>
            The country’s rich cultural history is evident in its everyday life, from the ancient temples and historic landmarks
            to traditional festivals such as Chinese New Year and the Mid-Autumn Festival. With a cuisine that varies across regions,
            from the fiery flavors of Sichuan to the delicate dishes of Cantonese food, there is always something new to explore.
          </p>
        </div>
        <div className='map-and-key-container'>
          <ChinaMap selectedCity={selectedCity} nextCity={nextCity} prevCity={prevCity} selectedIndex={selectedIndex} />
          {/* Display city information */}
          {selectedCity && (
            <div className="city-info hide-on-small">
              <p className="city-name">{selectedCity.name}</p>
              <p>{selectedCity.shortSummary}</p>
            </div>
          )}
        </div>
        <div className='text-container'>
          <p className='life-paragraph'>
            <strong>Why China? </strong>
            <p></p>
            <ul>
              <li><strong>Affordable Living Costs:</strong> China offers a lower cost of living compared to many Western countries, with a single person's estimated monthly expenses around 556.8 USD (4,027.2 CNY) - excluding rent.</li>
              <div className='inline-image'>
                <img src="/images/shanghai12.jpeg" alt="Shanghai food" />
              </div>
              <li><strong>Low Rent Prices:</strong> Rent is significantly lower—by an average of 71.9%—compared to the US, allowing for savings or a better quality living space.</li>
              <li><strong>Attractive Teaching Salaries:</strong> Teachers in China often earn salaries that are higher than those in many Western countries.</li>
              <li><strong>Diverse Living Environments:</strong> From bustling urban centers like Shanghai and Beijing to serene rural areas, China offers a range of living experiences.</li>
              <li><strong>Cultural Experience:</strong> Immersive cultural experiences with rich history, diverse traditions, and vibrant communities await.</li>

              <li><strong>Professional Growth:</strong> Teaching abroad enhances personal and professional development and provides valuable international work experience.</li>
              <li><strong>Social Networking Opportunities:</strong> Connect with locals and expatriates, fostering friendships and professional networks.</li>
              <li><strong>Exploration Opportunities:</strong> Diverse landscapes and historic landmarks offer ample travel opportunities, from the Great Wall to beautiful rural areas.</li>
            </ul>

          </p>
          <p>For detailed insights on the cost of living in China, you can refer to <a href="https://msadvisory.com/cost-of-living-in-china/">msadvisory</a>, which provides comprehensive data and analysis.</p>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Life;
