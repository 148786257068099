import React from 'react';
import Header from '../../components/header';
import Footer from '../../components/footer';
import { useNavigate } from 'react-router-dom';
import '../footer/ContactAbout.css';  // Assuming you're reusing the styles

function PaymentCancel() {
  const navigate = useNavigate();

  return (
    <div>
      <Header />
      <div>
        <div className="content-container">
          <div className="content payment-success">
            <h1>Payment Cancelled</h1>
            <p>Your payment has been cancelled. Try again via post page!</p>
            <button onClick={() => navigate('/post')} className="opening-button">
              Back to Post a Job
            </button>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default PaymentCancel;
