import React from 'react'
import JobSingleCard from './JobSingleCard'
import './Jobs.css'

const JobsCards = ({ jobs }) => {
    return (
        <div className='cards-container'>
            {jobs.map((item) => (
                <JobSingleCard key={item._id} job={item}/>
            ))}
        </div>
    )
}

export default JobsCards