
import Header from '../../components/header';
import Footer from '../../components/footer';
// src/pages/LoginPage.js
import { useState } from 'react';
import { useAuth } from '../../context/AuthContext';
import './Login.css'

const ForgotPassword = () => {
    const [email, setEmail] = useState('');
    const { forgotPassword, errorMessage } = useAuth();


    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            // Attempt to send email to forgot password
            await forgotPassword(email);
            console.log(errorMessage)
            //  navigate('/post')
        } catch (error) {
            console.error('Login failed', error);
            console.log(errorMessage)
        }
    };

    return (
        <div className="app-home">
            <Header />
            <div className='content-container'>
                <div className='center-container'>
                    <div className="login-container">
                        <h2>Password Reset</h2>
                        <form className="login-form" onSubmit={handleSubmit}>
                            <label>User Email:</label>
                            <input
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />

                            <button type="submit">Reset Password</button>
                            {errorMessage && <div className="error-message">{errorMessage}</div>}
                        </form>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default ForgotPassword;
