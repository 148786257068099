import React, { useEffect, useState } from 'react';
import Header from '../../components/header';
import Footer from '../../components/footer';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import './payment.css'
import { BACKEND_DOMAIN } from '../../constants'



import '../footer/ContactAbout.css';  // Assuming you're reusing the styles

function PaymentSuccess() {
  const { user } = useAuth();
  const [sessionData, setSessionData] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if(user){
      axios
          .get(`${BACKEND_DOMAIN}/jobs/latest-payment/${user._id}`)  // Fetch session details from your backend
          .then((res) => {
            console.log(res.data.payment);
            setSessionData(res.data.payment)})
          .catch((err) => console.error(err));
    }
  }, [user]);

  return (
    <div>
      <Header />
      <div>
        <div className="content-container">
          <div className="content payment-success">
            <h1>Payment Successful!</h1>
            {sessionData ? (
              <div>
                <p>Transaction ID: {sessionData.sessionId}</p>
                <p>Credits Purchased:{sessionData.amount}</p>
                <p>Thank you for your purchase!</p>
              </div>
            ) : (
              <p>Loading transaction details...</p>
            )}
            <button onClick={() => navigate('/post')} className="opening-button">
              Go to post a job
            </button>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default PaymentSuccess;
