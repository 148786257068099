import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import logo from '../images/teachlogo.png';
import './HeaderFooter.css'
import { useAuth } from '../context/AuthContext';
import { Link } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import React, { useState } from 'react'


function Header() {
    const { user, logout } = useAuth();
    const { enqueueSnackbar } = useSnackbar();
    const [isDropdownOpen, setDropdownOpen] = useState(false);

      const toggleDropdown = () => {
        setDropdownOpen(!isDropdownOpen);
      };


    const signOut = () => {
      logout()
      enqueueSnackbar('Signed Out', { variant: 'success' })
    }

    return (
      <nav>
       <div className={`nav-center`}>
        {/* <!-- nav header --> */}
        <div className="nav-header">
          
          <a href="/">
          <img src={logo} className="logo" alt="logo" />
          </a>
          <div className="nav-title">
            <a  href="/">
            <h4>China</h4>
            <h4>Teaching</h4>
            <h4>Jobs</h4>
            </a>
          </div>
          
        </div>
        {/* <!-- links --> */}
        <ul className="links">
          <li>
            <a href="/">jobs board</a>
          </li>
          <li>
            <a href="/life">Life in China</a>
          </li>
          <li>
            <a href="/post">post a job</a>
          </li>
        </ul>
        {user ? (
            <button onClick={signOut} className='logout'>Sign Out</button>
            ) : (
              // <button onClick={signOut} className='logout'>Sign Out</button>
            <Link to="/login" className='login'>Sign In</Link>
           )} 
        <button className="nav-toggle" onClick={toggleDropdown}>
            <FontAwesomeIcon icon={faBars} />
        </button>
        {isDropdownOpen && (
        <ul className="dropdown">
          <li><a href="/">Jobs Board</a></li>
          <li><a href="/post">Post a Job</a></li>
          <li><a href="/life">Life in China</a></li>
          {user ? (
            <li><button onClick={signOut}>Sign Out</button></li>
          ) : (
            <li><a href="/login">Sign In</a></li>
          )}
        </ul>
      )}
      </div>
     
    </nav>
    )
}

export default Header;
