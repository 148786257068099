import React, { useState } from 'react'
import axios from 'axios';
import BackButton from '../../components/BackButton';
import Spinner from '../../components/Spinner'
import { useNavigate, useParams } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import Header from '../../components/header';
import Footer from '../../components/footer';
import './Actions.css'
import { useAuth } from '../../context/AuthContext';
import { BACKEND_DOMAIN } from '../../constants'



const DeleteJob = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar()
  const { token } = useAuth();


  const handleDeleteJob = () => {
    setLoading(true);
    axios
      .delete(`${BACKEND_DOMAIN}/jobs/${id}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
      .then(() => {
        setLoading(false);
        enqueueSnackbar('Job deleted successfully', { variant: 'success' })
        navigate('/post')
      })
      .catch((error) => {
        setLoading(false);
        enqueueSnackbar('error', { variant: 'error' })
        console.log(error)
      })
  }

  return (
    <div className="app-home">
      <Header />
      <div className='content-container'>
        <div className='background-container'>
          {loading ? <Spinner /> : ''}
          <div className='action-content'>
            <div className='title-container'>
              <BackButton />
              <h1 className='title-text'>Delete Job</h1>
            </div>
            <h3 className=''>Are you sure you want to delete this job?</h3>
            <button
              className='delete-button'
              onClick={handleDeleteJob}
            >
              Yes, delete it.
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default DeleteJob