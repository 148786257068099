import React, { useState } from 'react'
import axios from 'axios';
import BackButton from '../../components/BackButton.js';
import Spinner from '../../components/Spinner.js'
import { useNavigate } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import Header from '../../components/header.js';
import Footer from '../../components/footer.js';
import './Actions.css'
import { useAuth } from '../../context/AuthContext.js';
import { minOptions, maxOptions, locations, sublocations, specialisms, instructions } from '../../constants.js'
import { RxQuestionMarkCircled } from "react-icons/rx";
import InfoModal from '../../components/InfoModal.js'
import { BACKEND_DOMAIN } from '../../constants'


const CreateJob = () => {
  const [showModal, setShowModal] = useState(false)
  const [infoText, setInfoText] = useState('')
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar();
  const { token } = useAuth();
  // info related states
  const [position, setPosition] = useState('');
  const [school, setSchool] = useState('');
  const [description, setDescription] = useState('');
  const [type, setType] = useState('');
  const [location, setLocation] = useState('');
  const [sublocation, setSublocation] = useState('');
  const [specialism, setSpecialism] = useState('');
  const [contactEmail, setcontactEmail] = useState('');
  const [salaryMin, setSalaryMin] = useState(minOptions[0]);
  const [salaryMax, setSalaryMax] = useState(maxOptions[maxOptions.length - 1]);
  const [expiryDate, setExpiryDate] = useState('');

  const cities = location ? sublocations[location] : [];
  

  const handleSaveJob = () => {
    const data = {
      position,
      school,
      description,
      type,
      specialism,
      location,
      sublocation,
      salaryMin,
      salaryMax,
      contactEmail,
      expiryDate: new Date(expiryDate),
    }
    setLoading(true);
    axios
      .post(`${BACKEND_DOMAIN}/jobs`, data, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
      .then(() => {
        setLoading(false);
        enqueueSnackbar('Job created successfully', { variant: 'success' })
        navigate('/post');
      })
      .catch((error) => {
        console.log(error.response.data.message)
        enqueueSnackbar(error.response.data.message, { variant: 'error' })
        setLoading(false)
      })
  }

  

  const openInfoModal = (info) => {
    setInfoText(instructions[info])
    setShowModal(true)
  }

  return (
    <div className="app-home">
      <Header />
      {/* container for everything within header */}
      <div className='content-container'>
        {/* container for background image */}
        <div className='background-container'>

          {loading ? <Spinner /> : ''}
          <div className='action-content'>
            <div className='title-container'>
              <BackButton />
              <h1 className='title-text'>Create Job</h1>
            </div>
            <p>Welcome to the job creation page. Please ensure each advertisement is for a single job position—ads containing multiple roles will be removed. All job details must be accurately filled out; The expiry date cannot be changed after submitting the ad. Ads with conflicting information (e.g., mismatched city and description) will be deleted. Expired job postings are automatically removed daily, and jobs displayed at the top of the website are those closest to their expiration. For additional guidance, click the question marks throughout the form.</p>
            <div className='form-group'>
              <label>Position: <RxQuestionMarkCircled className='info-modal' onClick={() => openInfoModal('position')} /> </label>
              <input
                type='text'
                value={position}
                onChange={(e) => setPosition(e.target.value)}
              />
            </div>
            <div className='form-group'>
              <label>School: <RxQuestionMarkCircled className='info-modal' onClick={() => openInfoModal('school')} /></label>
              <input
                type='text'
                value={school}
                onChange={(e) => setSchool(e.target.value)}
              />
            </div>
            <div className='form-group'>
              <label>Description: <RxQuestionMarkCircled className='info-modal' onClick={() => openInfoModal('description')} /></label>
              <textarea
                type='text'
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                className='description-edit'
              />
            </div>
            <div className='form-group'>
              <label>Type: <RxQuestionMarkCircled className='info-modal' onClick={() => openInfoModal('type')} /></label>
              <select value={type} onChange={(e) => setType(e.target.value)}>
                <option value="">Select Job Type</option>
                <option value="Full-time">Full-time</option>
                <option value="Part-time">Part-time</option>
                <option value="Internship">Internship</option>
                <option value="Short contract/seasonal">Short contract / seasonal</option>
              </select>
            </div>
            <div className='form-group'>
              <label>Specialism: <RxQuestionMarkCircled className='info-modal' onClick={() => openInfoModal('specialism')} /></label>
              <select value={specialism} onChange={(e) => setSpecialism(e.target.value)}>
                <option value="">Select Specialism</option>
                {specialisms.map((special, index) => (
                  <option key={index} value={special}>{special}</option>
                ))}
              </select>
            </div>
            <div className='form-group'>
              <div className='location-container'>
                <label>Location: <RxQuestionMarkCircled className='info-modal' onClick={() => openInfoModal('location')} /></label>
                <label className='extra-margin-right'></label>
                <select value={location} onChange={(e) => setLocation(e.target.value)}>
                  <option value="">Select Job Location</option>
                  {locations.map((location, index) => (
                    <option key={index} value={location}>{location}</option>
                  ))}
                </select>
                <label className='extra-extra-margin-right'></label>
                <label>City: <RxQuestionMarkCircled className='info-modal' onClick={() => openInfoModal('sublocation')} /></label>
                <label className='extra-margin-right'></label>
                <select
                  value={sublocation}
                  onChange={(e) => setSublocation(e.target.value)}
                  disabled={!location} // Disable if no province is selected
                >
                  <option value="">Select city</option>
                  {cities.map((city, index) => (
                    <option key={index} value={city}>{city}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className='form-group'>
              <label>Salary Range: <RxQuestionMarkCircled className='info-modal' onClick={() => openInfoModal('salary')} /></label>
              <div className='salary-container'>
                <label className='extra-margin-right'>
                  Min Salary:
                  <div className='currency-input'>
                    <span>¥</span>
                    <input
                      type='number'
                      value={salaryMin}
                      onChange={(e) => setSalaryMin(e.target.value)}
                    />
                    <span> - </span>
                  </div>
                </label>
                <label>
                  Max Salary:
                  <div className='currency-input'>
                    <span>¥</span>
                    <input
                      type='number'
                      value={salaryMax}
                      onChange={(e) => setSalaryMax(e.target.value)}
                    />
                  </div>
                </label>
              </div>
            </div>
            <div className='form-group'>
              <label>Expiry Date: <RxQuestionMarkCircled className='info-modal' onClick={() => openInfoModal('expiry')} /></label>
              <input
                type='date'
                value={expiryDate}
                onChange={(e) => setExpiryDate(e.target.value)}
              />
            </div>
            <div className='form-group'>
              <label>Contact email: <RxQuestionMarkCircled className='info-modal' onClick={() => openInfoModal('poster')} /></label>
              <input
                type='text'
                value={contactEmail}
                onChange={(e) => setcontactEmail(e.target.value)}
              />
            </div>
            <button className='submit-button' onClick={handleSaveJob}>
              Save
            </button>
          </div>
        </div>
        {
          showModal && (
            <InfoModal info={infoText} onClose={() => {
              setShowModal(false)
            }} />
          )
        }
      </div>
      <Footer />
    </div>
  )
}

export default CreateJob