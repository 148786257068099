import { Link } from 'react-router-dom'
import { BsArrowLeft } from 'react-icons/bs'

const BackButton = ({ destination = '/post'}) => {
  return (
    <div>
        <Link
        to={destination}
        >
            <BsArrowLeft className='back-arrow'></BsArrowLeft>
        </Link>
    </div>
  )
}

export default BackButton